import Vue from 'vue'
import App from './App.vue'
import 'vue-awesome/icons/flag';
import 'vue-awesome/icons';
import Icon from 'vue-awesome/components/Icon';
import './assets/css/tab-component.css';
import './assets/css/main.scss';

import Routes from './routes/routes';
import VueResource from 'vue-resource';
import AsyncComputed from 'vue-async-computed';
import { VclFacebook, VclInstagram, VueContentLoading, VclTable  } from 'vue-content-loading';
import vuetify from "@/plugins/vuetify";

require('./env');
import Vuelidate from 'vuelidate';
window.Pusher = require('pusher-js');

import Notifications from 'vue-notification';
import VueTabsWithActiveLine from "./components/modules/VueTabsWithActiveLine";
import store from './store';
import Lightbox from 'vue-easy-lightbox'
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

import vWow from 'v-wow';
Vue.use(vWow);

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo);

Vue.use(Lightbox);


import moment from 'moment';

Vue.component('Tabs', VueTabsWithActiveLine);



Vue.filter('truncate', function (text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
})


Vue.filter('moment', function(value, format='MMM DD, YYYY') {
    if (value) {
        return moment.utc(String(value)).format(format);
    } else {
        return moment.utc().format(format);
    }
});
Vue.filter('formatTime', function(value) {
    if (value) {
        return moment.utc(String(value)).format('HH:mm');
    } else {
        return moment.utc().format('HH:mm');
    }
});
Vue.filter('formatFullTime', function(value) {
    if (value) {
        return moment.utc(String(value)).format('YYYY/MM/DD HH:mm:ss');
    } else {
        return moment.utc().format('YYYY/MM/DD HH:mm:ss');
    }
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment.utc(String(value)).format('DD.MM.YYYY HH:mm');
    } else {
        return moment.utc().format('DD.MM.YYYY HH:mm');
    }
});

Vue.filter('formatYMD', function(value) {
    if (value) {
        return moment.utc(String(value)).format('DD.MM.YYYY');
    } else {
        return moment.utc().format('DD.MM.YYYY');
    }
});
Vue.use(Vuelidate);

Vue.use(Notifications);

Vue.component('v-icon', Icon);

require('./locale');
require('./global_function');
Vue.use(VueResource);

Vue.config.productionTip = false;

Vue.http.options.root= (process.env.NODE_ENV === 'production') ? Vue.prototype.$APP_URL+'/' : 'http://localhost:3000/';

var tokenCSRF = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
Vue.http.interceptors.push(request=> {
    request.headers.set('X-CSRF-TOKEN', tokenCSRF);
});

Vue.use(AsyncComputed);
Vue.component('VclFacebook', VclFacebook);
Vue.component('VclInstagram', VclInstagram);
Vue.component('VueContentLoading', VueContentLoading);
Vue.component('VclTable', VclTable);


// vue tabs
import {Tabs, Tab} from 'vue-tabs-component';
Vue.component('SpatieTabs', Tabs);
Vue.component('SpatieTab', Tab);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);


const i18n = new VueI18n({
    locale: 'en',
    messages: {
        'ua': {
            validation: {
                required: 'Це поле обов\'язкове',
            }
        },
        'en': {
            validation: {
                required: 'This field is required',
            }
        },
        'ru': {
            validation: {
                required: 'Это поле обязательно к заполнению',
            }
        },
        'pl': {
            validation: {
                required: 'To pole jest wymagane',
            }
        },
        'tr': {
            validation: {
                required: 'Bu alan gereklidir',
            }
        }

    }
});

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

//import * as validators from "vuelidate/lib/validators";


/*


*/
//import vee from "@/plugins/vuelidate";

new Vue({
    router: Routes,
    store,
    vuetify,
    render: h => h(App),
    i18n: i18n
}).$mount('#app');


