<template>
    <div class="home-page" >
        <main>
            <section class="full-h-block fu-block relative pc top-content">
                <VueSlickCarousel v-bind="settings1" :pauseOnHover="false" ref="carousel" v-if="filteredHeaders.length">
                    <div v-for="(header, index) in filteredHeaders" :key="index" class="slide-block">
                        <div class="videoBack" :style="{ backgroundImage: `url(${header.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }" style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:-1">
                            <div class="bg"></div>
                            <div class="container pc">
                                <div class="content">
                                    <div class="title-block flex flex-col" style="gap:10px">
                                        <h1 style="color:white;font-weight:400">{{ header.title }}</h1>
                                        <p class="text-o">{{ header.description }}</p>
                                        <div class="button-container inline-flex flex-row" style="gap:10px">
                                          <p @click="scrollToForm">
                                            <router-link :to="{ name: 'home', params: {lang: $store.getters.getLang}, hash: '#form' }"
                                                         class="link-button flex flex-row justify-between items-center h3-font white" style="gap:10px;font-weight:700"
                                            >

                                              <span>
                                                  {{ __('Leave request') }}
                                                  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M1.5 11L6.5 6L1.5 1" stroke="#12204D" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                  </svg>
                                              </span>
                                            </router-link>
                                          </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </VueSlickCarousel>
            </section>
            <section class="catalog pc" id="catalog" v-if="home" >
                <div class="container content flex flex-col pc" >
                    <h2 class="big-h2" style="font-weight: 400">
                        {{__('Real estate catalog')}}
                    </h2>
                    <div class="catalog-filters flex">
                        <div class="select-container flex flex-col ">
                            <label class="p-font color-main" >{{__('Country')}}</label>
                            <div class="select-wrapper color-main" v-if="home.countries">
                                <DropdownDefault
                                        :items="home.countries"
                                        value_key="id"
                                        v-model="form.country"
                                        text="name"
                                        placeholder="Select a country"
                                ></DropdownDefault>
                            </div>
                        </div>
                        <div class="select-container flex flex-col ">
                            <label class="p-font color-main" >{{__('City')}}</label>
                            <div class="select-wrapper  color-main" v-if="home.cities">
                                <DropdownDefault
                                        v-if="form.country"
                                        :items="countrieCity.cities"
                                        value_key="id"
                                        v-model="form.city"
                                        text="name"
                                        placeholder="Select a city"
                                ></DropdownDefault>
                                <DropdownDefault
                                        v-else
                                        disabled=""
                                        placeholder="Select a city"
                                ></DropdownDefault>

                            </div>
                        </div>
                        <div class="input-container flex flex-col input-block">
                            <label class="p-font color-main" >{{__('Min price, from')}}</label>
                            <div class="select-container">
                                <InputLabelTextDefault v-model="form.min_price" type="number" name="min" value="" inputmode="numeric"/>
                                <div class="suffix input-font main-color">$</div>
                            </div>
                        </div>
                        <div class="input-container flex flex-col input-block">
                            <label class="p-font color-main" >{{__('Max price, before')}}</label>
                            <div class="select-container ">
                                <InputLabelTextDefault v-model="form.max_price" type="number" name="max" value="" inputmode="numeric"/>
                                <div class="suffix input-font main-color">$</div>
                            </div>
                        </div>
                        <div class="btn-search">
                            <router-link :to="{name: 'catalog', query: this.form, params: { lang: $store.getters.getLang}}" class="link-button flex flex-row items-center mainColor" style="gap: 10px;">
                            <span>
                                {{__('Search')}}
                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.5 11L6.5 6L1.5 1" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            </router-link>
                        </div>
                        <div class="filters-row flex">
                            <div class="flex buttons-container flex-col" v-if="home.categories">

                                <div class="buttons-wrapper flex flex-row items-center flex-wrap" style="gap:10px" >
                                    <div class="p-font color-main label-class">{{__('Plans:')}}</div>
                                    <a
                                            type="button"
                                            class="property-icon p-font"
                                            v-for="category in home.categories"
                                            :key="category.id"
                                            :class="{ active: isCategoryActive(category.id, 'category') }"
                                            @click="toggleCategory(category.id, 'category')"
                                    >
                                        {{ category[lang] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="filters-row flex">
                            <div class="flex buttons-container flex-col" v-if="home.extras">

                                <div class="buttons-wrapper flex flex-row items-center flex-wrap" style="gap:10px" >
                                    <div class="p-font color-main label-class">{{__('Extra:')}}</div>
                                    <a
                                            type="button"
                                            class="property-icon p-font"
                                            v-for="extra in home.extras"
                                            :key="extra.id"
                                            :class="{ active: isCategoryActive(extra.id, 'extra') }"
                                            @click="toggleCategory(extra.id, 'extra')"
                                    >
                                        {{ extra[lang] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="catalog-objects" v-if="home.objects">
                        <ul>
                            <li v-for="object in home.objects" :key="object.id" class="object-card">
                                <router-link :to="{ name: 'item_Page', params: { url: object.url, lang: $store.getters.getLang } }">
                                    <div class="object-image" :style="{ backgroundImage: 'url(' + object.images[0].path + ')' }" v-if="object.images.length"></div>
                                    <div class="object-image" :style="{ backgroundImage: 'url(/dist/images/default-img.svg)' }" v-else></div>
                                    <div class="object-info">
                                        <div class="name-price">
                                            <p class="name">{{ object.name }}</p>
                                            <p class="price">{{  object.currency ?  object.currency.symbol : '$' }}{{ object.min_price.toLocaleString() }} <span class="p" v-if="object.max_price && object.max_price > 0">- {{  object.currency ?  object.currency.symbol : '$' }}{{ object.max_price.toLocaleString() }}</span> <span>{{__('Price')}}</span></p>
                                        </div>
                                        <div class="block">
                                            <p><img src="/dist/images/icon-home.svg" alt=""> Q{{ object.quarter }} {{ object.years }}</p>
                                            <p><img src="/dist/images/icon-location.svg" alt="">{{ object.address }}</p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
            <section class="quote-block relative pc flex" id="about">
                <div class="image-wrapper">
                    <img alt="" loading="lazy" width="1000" height="1000" decoding="async" data-nimg="1" style="color:transparent;object-fit:cover"  src="/dist/images/slavik.webp">
                </div>
                <div class="container pc without-header container_pc_about">
                    <div class="content">
<!--                        <p class="text-o">{{__('Our motto')}}</p>-->
                        <h2>{{__('Envisioning a world where every real estate transaction is seamless, we at Amphitrite bridge dreams to reality with integrity, innovation, and an unwavering commitment to excellence in service.')}}</h2>
                        <h2 class="our-motto">{{__('Our motto: Impeccable, Créativité, Sérénité')}}</h2>
                        <div class="block-text">
                            <p class="text-o">{{__('Slavik, Owner of Amphitrite Real Estate')}}</p><a href="mailto:slavik@aft.broker">
                            <p style="font-weight:400">{{__('slavik@aft.broker')}}</p>
                        </a>
                        </div>
                        <div class="block-btn">
                            <router-link :to="{ name: 'pages', params: { lang, page: 'our-story' } }" class="link-button flex flex-row justify-between items-center h3-font white" style="font-weight:700">
                                <span>
                                    {{__('Our team')}}
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 11L6.5 6L1.5 1" stroke="#12204D" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </router-link>
                        </div>

                    </div>
                </div>
            </section>
            <section class="stages-block pc" id="services" ref="services">
                <div class="container pc">
                    <div class="content flex flex-col">
                        <div class="title-block flex flex-col items-center justify-center">
                            <h2 class="h1-font" style="font-weight: 400">{{__('Real Estate Journey')}}</h2>
                            <p class="text-o" >{{__('Embark on a seamless property acquisition journey with us. Three key steps to secure your dream investment with ease and confidence.')}}</p>
                            <p @click="scrollToForm">
                              <router-link :to="{ name: 'home', params: {lang: $store.getters.getLang}, hash: '#form' }" class="link-button flex flex-row items-center mainColor" style="gap:10px">

                                  <span>
                                      {{__('Leave request')}}
                                      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1.5 11L6.5 6L1.5 1" stroke="#fff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                  </span>
                              </router-link>
                            </p>
                        </div>
                        <div class="image-cards-container flex">
                            <div class="image-card flex flex-col">
                                <div class="img-wrapper">
                                    <img alt="" loading="lazy" width="600" height="600" decoding="async" data-nimg="1" class="img-item" style="color:transparent" src="/dist/images/Rectangle_1488_1_e8b1cfec65.webp">
                                    <img alt="" loading="lazy" width="10" height="40" decoding="async" data-nimg="1" class="image-line top" style="color:transparent;position:absolute" src="/dist/images/image-line-white.svg">
                                    <img alt="" loading="lazy" width="10" height="40" decoding="async" data-nimg="1" class="image-line" style="color:transparent;position:absolute" src="/dist/images/image-line.svg">
                                </div>
                                <div class="flex flex-col" style="gap:10px">
                                    <h3 class="h2-font" style="font-weight:300"> {{__('Personal consultation')}}</h3>
                                    <p >{{__('We begin by understanding your vision and preferences. Our experts provide tailored advice to align with your real estate goals, ensuring a perfect match for your lifestyle or investment needs.')}}</p>
                                </div>
                            </div>
                            <div class="image-card flex flex-col">
                                <div class="img-wrapper">
                                    <img alt="" loading="lazy" width="600" height="600" decoding="async" data-nimg="1" class="img-item" style="color:transparent" src="/dist/images/Rectangle_1488_2_c8c94b92dd.webp">
                                    <img alt="" loading="lazy" width="10" height="40" decoding="async" data-nimg="1" class="image-line top" style="color:transparent;position:absolute" src="/dist/images/image-line.svg">
                                    <img alt="" loading="lazy" width="10" height="40" decoding="async" data-nimg="1" class="image-line" style="color:transparent;position:absolute" src="/dist/images/image-line.svg">
                                </div>
                                <div class="flex flex-col" style="gap:10px">
                                    <h3 class="h2-font" style="font-weight:300">{{__('Seamless Property Selection')}}</h3>
                                    <p >{{__('Dive into our curated listings with virtual tours and detailed insights. We hand pick options that resonate with your criteria, offering a hassle-free selection process with our expert guidance at every step.')}}</p>
                                </div>
                            </div>
                            <div class="image-card flex flex-col">
                                <div class="img-wrapper">
                                    <img alt="" loading="lazy" width="600" height="600" decoding="async" data-nimg="1" class="img-item" style="color:transparent" src="/dist/images/Rectangle_1488_1_e8b1cfec65.webp">
                                    <img alt="" loading="lazy" width="10" height="40" decoding="async" data-nimg="1" class="image-line top" style="color:transparent;position:absolute" src="/dist/images/image-line-white.svg">
                                    <img alt="" loading="lazy" width="10" height="40" decoding="async" data-nimg="1" class="image-line" style="color:transparent;position:absolute" src="/dist/images/image-line.svg">
                                </div>
                                <div class="flex flex-col" style="gap:10px">
                                    <h3 class="h2-font" style="font-weight:300">{{__('Secure Transaction Support')}}</h3>
                                    <p>{{__('Finalize your dream property with confidence. We navigate the paperwork, legalities, and negotiations, delivering a transparent and secure transaction. Post-sale, our dedicated support continues to assist you in settling into your new investment.')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="team-block pc" id="team" >
                <div class="container pc" >
                    <div class="content flex flex-col fit-h">
                        <div class="title-container flex">
                            <div class="flex flex-col" style="gap:20px">
                                <h2 class="big-h2" style="font-weight: 400">{{__('Team that creates great things')}}</h2>
                                <p class="text-o">{{__("We don't just sell real estate, we create a luxurious experience for our clients")}}</p>
                            </div>
                            <div class="flex flex-row prev-next-buttons" style="gap:20px">
                                <button @click="prevSlide" class="link-button  flex-row justify-between items-center mainColor " style="gap:10px">
                                    <span>
                                        <svg style="transform: rotate(180deg)" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M1.5 11L6.5 6L1.5 1" stroke="#ffffff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        {{__('Prev')}}
                                    </span>
                                </button>
                                <button @click="showNext" class="link-button  flex-row justify-between items-center mainColor " style="gap:10px">
                                    <span>
                                        {{__('Next')}}
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M1.5 11L6.5 6L1.5 1" stroke="#ffffff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <VueSlickCarousel v-bind="settings" ref="carousel" v-if="home">
                    <div class="block" v-for="member in home.team" :key="member.name">
                        <img :alt="member.name"  loading="lazy" width="600" height="600" decoding="async" data-nimg="1" :src="member.image" style="color: transparent;">
                        <div class="h2-font">{{ member.name }}</div>
                        <p class="text-o">{{ member.position }}</p>
                    </div>
                </VueSlickCarousel>
            </section>
            <section class="full-h-block relative without-header pc">
                <img alt="" loading="lazy" decoding="async" data-nimg="fill" style="position:absolute;height:100%;width:100%;left:0;top:0;right:0;bottom:0;object-fit:cover;color:transparent" sizes="(max-width: 768px) 150vw" src="/dist/images/Rectangle_1493_14daef7788.webp">
                <div class="bg"></div>
                <div class="container pc ">
                    <div class="content flex items-center justify-center text-center">
                        <div class="title-block flex flex-col items-center" style="gap:20px">
                            <h2 style="color:white;font-weight:400" class="h1-font full-h1">{{__('Special offers for our customers')}}</h2>
                            <p style="color: white" >{{__('Exclusive deals await! Click to access our special offers tailored for discerning clients. Unveil a selection of premium properties at exceptional prices. Act now and take the first step towards luxury living.')}}</p>
                            <div class="button-container flex flex-row" style="gap:10px;margin-top:120px">
                                <a @click="dialogForm = true" class="link-button flex flex-row justify-between items-center h3-font white" style="gap:10px;font-weight:700">
                                    <span>
                                        {{__('Get an offer')}}
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.5 11L6.5 6L1.5 1" stroke="#12204D" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="form-block relative pc" id="form"  ref="form_footer">
                <div class="container relative pc">
                    <div class="content flex">
                        <div class="text-container flex flex-col color-main">
                            <h2 class="big-h2" style="font-weight: 400">{{__('Unlock Your Dream Home Today!')}}</h2>
                            <p class="text-o text-ttt">{{__("Ready to take the first step towards your dream property? Fill out the form to connect with our experts. We'll guide you through every stage of the process, ensuring a smooth and successful real estate experience.")}}</p>
                            <div class="flex flex-row text-ttt" style="gap:10px">
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.telegram')">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Telegram">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
                                    </svg>
                                </a>
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.whatsapp')">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-whatsapp" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Whats&#39;App">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9"></path>
                                        <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1"></path>
                                    </svg>
                                </a>
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.instagram')">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Instagram">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
                                        <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                        <path d="M16.5 7.5l0 .01"></path>
                                    </svg>
                                </a>
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.facebook')">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Facebook">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                    </svg>
                                </a>

                            </div>
                        </div>
                        <FormContactHome></FormContactHome>
                        <div class="text-t-mob">
                            <p class="text-o">{{__("Ready to take the first step towards your dream property? Fill out the form to connect with our experts. We'll guide you through every stage of the process, ensuring a smooth and successful real estate experience.")}}</p>
                            <div class="flex flex-row" style="gap:10px">
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.telegram')">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Telegram">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
                                    </svg>
                                </a>
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.whatsapp')">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-whatsapp" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Whats&#39;App">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9"></path>
                                        <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1"></path>
                                    </svg>
                                </a>
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.instagram')">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Instagram">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
                                        <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                        <path d="M16.5 7.5l0 .01"></path>
                                    </svg>
                                </a>
                                <a target="_blank" class="flex items-center social-link" style="color:inherit;padding:8px;background-color:var(--bgGreyColor);font-weight:700" :href="__('social_links.facebook')">
                                    <svg class="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" alt="Facebook">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                    </svg>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>



        <ModalDefault v-if="dialogForm" v-bind:modal_cancel.sync="dialogForm" width="490">
            <div class="window-form">
                <p class="name">{{__('Individual selection of real estate')}}</p>
                <FormWindow :countries="home.countries" :selectPrice="home.selectPrice" :deadline="home.deadline"></FormWindow>
            </div>
        </ModalDefault>

        <button v-if="showButton" @click="scrollToTop" class="btn-up">
            <svg width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.75 12.2501L10.5 3.23736C10.9485 2.7584 11.4905 2.37687 12.0927 2.11584C12.6947 1.85482 13.3438 1.72021 14 1.72021C14.6563 1.72021 15.3053 1.85482 15.9073 2.11584C16.5095 2.37687 17.0515 2.7584 17.5 3.23736L26.25 12.2501" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>

    </div>
<!--    <div v-else>-->
<!--        loading-->
<!--    </div>-->
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import _ from 'lodash';
    import FormContactHome from "../modules/FormContactHome";
    import DropdownDefault from "../modules/DropdownDefault";
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import FormWindow from "../modules/FormWindow";
    import ModalDefault from "../modules/ModalDefault";

    export default {
        name: 'home',
        data() {
            return {
                dialogForm: false,
                showButton: false,
                settings1: {
                    "autoplay": true,
                    "autoplaySpeed": 7000,
                    "dots": false,
                    "arrows": false,
                    "infinite": true,
                    "speed": 700,
                    "slidesToShow": 1,
                    "slidesToScroll": 1,
                    "initialSlide": 0,
                },
                settings: {
                    "centerMode": true,
                    "dots": false,
                    "infinite": true,
                    "speed": 500,
                    "slidesToShow": 5,
                    "slidesToScroll": 4,
                    "initialSlide": 0,
                    "responsive": [
                        {
                            "breakpoint": 1300,
                            "settings": {
                                "slidesToShow": 4,
                                "slidesToScroll": 1,
                                "infinite": true,
                                "dots": false
                            }
                        },
                        {
                            "breakpoint": 1030,
                            "settings": {
                                "slidesToShow": 3,
                                "slidesToScroll": 1,
                                "infinite": true,
                                "dots": false
                            }
                        },
                        {
                            "breakpoint": 600,
                            "settings": {
                                "slidesToShow": 2,
                                "slidesToScroll": 2,
                                "initialSlide": 2
                            }
                        },
                        {
                            "breakpoint": 480,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1
                            }
                        }
                    ]
                },
                activeExtras: [],
                activeCategories: [],
                form: {
                    search: null,
                    country: null,
                    city: null,
                    min_price: null,
                    max_price: null,
                    category: '[]',
                    extra: '[]',
                },
                home: null
            };
        },
        watch: {
            'form.country'() {
                this.form.city= null;
            },
            lang(newLang, oldLang) {
                if (newLang !== oldLang) {
                    this.fetchHomeData();
                }
            }
        },
        methods: {
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            },
            handleScroll() {
                this.showButton = window.scrollY > 200;
            },
            prevSlide() {
                this.$refs.carousel.prev();
            },
            showNext() {
                this.$refs.carousel.next();
            },
            toggleCategory(id, type= 'category') {
                let category= JSON.parse(this.form[type]);

                let find= _.indexOf(category, id);

                if(find >= 0) {
                    category.splice(find, 1);
                } else {
                    category.push(id);
                }

                this.form[type]= JSON.stringify(category);
            },
            isCategoryActive(id, type= 'category') {
                let category= JSON.parse(this.form[type]);

                return (_.indexOf(category, id) >= 0) ? true : false;
            },
            fetchHomeData() {
                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/home', { lang: this.lang })
                    .then(response => response.json())
                    .then(response => {
                        console.log('Data received:', response);
                        this.home = response.data;
                    })
                    .catch(err => {
                        console.error('Error fetching data:', err);
                    });
            },
            scrollToForm() {
              const el = this.$refs.form_footer;
              if (el) {
                el.scrollIntoView({behavior: 'smooth'});
              }
            }
        },
        created() {
            this.fetchHomeData();
        },
        computed: {
            filteredHeaders() {
                if (this.home && this.home.headers) {
                    return this.home.headers.filter(header => header.lang === this.lang);
                }
                return [];
            },
            countrieCity() {
                return (this.home) ? _.find(this.home.countries, {id: this.form.country}) : null;
            },
            languages() {
                return this.$store.state.language.list;
            },
            lang() {
                return this.$store.state.language.lang;
            },
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
            this.$root.$on('scroll-to', this.scrollToSection);
        },
        beforeDestroy() {
            this.$root.$off('scroll-to', this.scrollToSection);
            window.removeEventListener('scroll', this.handleScroll);
        },
        components: {
            FormWindow,
            FormContactHome,
            InputLabelTextDefault,
            DropdownDefault,
            VueSlickCarousel,
            ModalDefault
        }
    }
</script>
