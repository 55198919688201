<template>
  <section id = "" class = "calc-page">
    <div class = "container content flex flex-col pc">
      <SpatieTabs :options="{ useUrlFragment: false }">
        <SpatieTab name="First tab">
          This is the content of the first tab
          <FormPreSale/>
        </SpatieTab>
        <SpatieTab name="Second tab">
          This is the content of the second tab
        </SpatieTab>
      </SpatieTabs>
      <br>
  </section>
</template>


<script setup>
import FormPreSale from "../modules/FormPreSale";

export default {
  name: 'Calc',

  components: {
    FormPreSale
  }
};
</script>



<style scoped>

</style>