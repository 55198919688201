<template>
    <div class="text-pages privacy-page cookies-page">
        <div class="container pc">
            <div class="content-block">
                <div class="left">
                    <div class="container" v-if="page">
                        <h1>{{page.title}}</h1>
                        <div v-html="page.text" :class ="'page_content_'+page.id"></div>
                    </div>
                </div>
                <div class="right">
                    <FormContact></FormContact>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormContact from "../modules/FormContact";
export default {
  name: 'terms',
    data: function() {
      return {
          page: ''
      }
  },

  watch: {
      lang() {
    }
  },
    created() {
        this.fetchData(this.$route.params.page);
    },
    beforeRouteUpdate (to, from, next) {
        // обрабатываем изменение параметров маршрута...
        // не забываем вызвать next()
        this.fetchData(to.params.page);
        return next();
    },
  methods:{
      fetchData(page) {
          this.$http.get(`ajax/pages/${page}/`+ this.lang)
              .then(response => response.json())
              .then(response => {
                  console.log('Data received:', response);
                  this.page = response.data;
              })
              .catch(err => {
                  this.httpHandler(err);
              });
      },
  },
  computed: {
      lang() {
          return this.$store.state.language.lang;
      },
      languages() {
          return this.$store.state.language.list;
      },

  },
  mounted() {

  },
  components: {
      FormContact
  }
}
</script>
