<template>
<div class="blog-page" v-if="blogs">
    <vue-headful
            :title="blogs.title_seo + ' | Amphitrite'"
            :description="blogs.description + ' | Amphitrite'"
    />
    <div class="container pc">
        <div class="content-block">
            <div class="left">
                <div class="top">
                    <div>
                        <router-link :to="{ name: 'blog', params: { lang: $store.getters.getLang}}" class="back link-button flex-row justify-between items-center mainColor">
                           <span>
                              <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M6.28564 1.5L1.28564 6.5L6.28564 11.5" stroke="#12204D" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              {{__('Return to blog')}}
                          </span>
                        </router-link>
                        <p class="name">{{ blogs.title }}</p>
                    </div>
                    <p class="time">
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.6665 6.33333V4.66667C15.6665 4.44565 15.5787 4.23369 15.4224 4.07741C15.2661 3.92113 15.0542 3.83333 14.8332 3.83333H13.9998V4.66667C13.9998 4.88768 13.912 5.09964 13.7558 5.25592C13.5995 5.4122 13.3875 5.5 13.1665 5.5C12.9455 5.5 12.7335 5.4122 12.5772 5.25592C12.421 5.09964 12.3332 4.88768 12.3332 4.66667V3.83333H5.6665V4.66667C5.6665 4.88768 5.57871 5.09964 5.42243 5.25592C5.26615 5.4122 5.05418 5.5 4.83317 5.5C4.61216 5.5 4.4002 5.4122 4.24392 5.25592C4.08763 5.09964 3.99984 4.88768 3.99984 4.66667V3.83333H3.1665C2.94549 3.83333 2.73353 3.92113 2.57725 4.07741C2.42097 4.23369 2.33317 4.44565 2.33317 4.66667V6.33333H15.6665ZM15.6665 8H2.33317V13C2.33317 13.221 2.42097 13.433 2.57725 13.5893C2.73353 13.7455 2.94549 13.8333 3.1665 13.8333H14.8332C15.0542 13.8333 15.2661 13.7455 15.4224 13.5893C15.5787 13.433 15.6665 13.221 15.6665 13V8ZM13.9998 2.16667H14.8332C15.4962 2.16667 16.1321 2.43006 16.6009 2.8989C17.0698 3.36774 17.3332 4.00363 17.3332 4.66667V13C17.3332 13.663 17.0698 14.2989 16.6009 14.7678C16.1321 15.2366 15.4962 15.5 14.8332 15.5H3.1665C2.50346 15.5 1.86758 15.2366 1.39874 14.7678C0.929896 14.2989 0.666504 13.663 0.666504 13L0.666504 4.66667C0.666504 4.00363 0.929896 3.36774 1.39874 2.8989C1.86758 2.43006 2.50346 2.16667 3.1665 2.16667H3.99984V1.33333C3.99984 1.11232 4.08763 0.900358 4.24392 0.744078C4.4002 0.587797 4.61216 0.5 4.83317 0.5C5.05418 0.5 5.26615 0.587797 5.42243 0.744078C5.57871 0.900358 5.6665 1.11232 5.6665 1.33333V2.16667H12.3332V1.33333C12.3332 1.11232 12.421 0.900358 12.5772 0.744078C12.7335 0.587797 12.9455 0.5 13.1665 0.5C13.3875 0.5 13.5995 0.587797 13.7558 0.744078C13.912 0.900358 13.9998 1.11232 13.9998 1.33333V2.16667Z" fill="#BA974D"/>
                        </svg>
                        <span>{{blogs.created_at}}</span>
                    </p>
                </div>
                <div v-if="blogs.img" class="img" :style="{ backgroundImage: `url(${blogs.img})` }"></div>
                <div v-else class="img" :style="{ backgroundImage: 'url(/dist/images/default-img.svg)' }"></div>
                <div class="text" v-html="blogs.text">

                </div>
            </div>
            <div class="right">
                <FormContact></FormContact>
                <div class="right-b" @click="dialogForm = true">
                    <VueSlickCarousel v-bind="set" v-if="blogs.bannersGroup2">
                        <div class="block" v-for="member in blogs.bannersGroup2" :key="member.name">
                            <img loading="lazy" width="400"  decoding="async" data-nimg="1" :src="member.img" style="color: transparent;">
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>
    </div>
    <ModalDefault v-if="dialogForm" v-bind:modal_cancel.sync="dialogForm" width="690">
        <div class="window-form">
            <p class="name">{{__('Individual selection of real estate')}}</p>
            <FormWindow :countries="blogs.countries" :selectPrice="blogs.selectPrice" :deadline="blogs.deadline"  v-bind:modal_cancel.sync="dialogForm"></FormWindow>
        </div>
    </ModalDefault>
</div>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel';
    import vueHeadful from 'vue-headful'
    import FormContact from "../modules/FormContact";
    import FormWindow from "../modules/FormWindow";
    import ModalDefault from "../modules/ModalDefault";

    export default {
        name: 'BlogPage',
        data() {
            return {
                dialogForm:false,
                set: {
                    "arrows": false,
                    "dots": false,
                    "infinite": true,
                    "autoplay": true,
                    "autoplaySpeed": 5000,
                    "slidesToShow": 1,
                    "slidesToScroll": 1,
                    "initialSlide": 0,
                },
                blogs: false
            };
        },
        props: {
            post: {
                type: Object,
            },
        },
        watch: {
            lang() {
                this.createdComponent(this.$route.params.page);
            }
        },
        methods: {
            createdComponent(page) {
                let timeout = setInterval(() => {
                    this.progress += 1;
                    if(this.progress > 50) {
                        clearInterval(timeout)
                    }
                },15);
                this.$http.get("ajax/blog/" + this.lang + "/" + page)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                            this.blogs = response.data;
                            clearInterval(timeout);
                            this.progress = 100;
                            setTimeout(() => {
                                this.progress = 0;
                            }, 500);
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                    });
            }
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.createdComponent(to.params.page);

            return next();
        },

        created() {
            this.createdComponent(this.$route.params.page);
        },
        computed: {
            languages() {
                return this.$store.state.language.list;
            },
            lang() {
                return this.$store.state.language.lang;
            },
        },
        components: {
            VueSlickCarousel,
            ModalDefault,
            FormWindow,
            vueHeadful,
            FormContact,
        }
    }
</script>