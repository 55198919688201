<template>
  <div v-if="loading">
    loading
  </div>
  <div style="background:transparent;" class="language" v-else>
    <v-menu offset-y v-if="languages && active_language" v-model="languages_menu">
      <template v-slot:activator="{ on }">
        <v-list class="pa-0 block-lang" style="background:transparent;">
          <v-list-item-action v-on="on">
            <!--                    <v-btn text>-->
            <v-list-item class="text-right">

<!--              <img :src="`/dist/images/flags/${active_language.lang}.svg`" :alt="`${active_language.lang}`" />-->
              {{active_language.lang}}
              <img src="/dist/images/image-lang.svg" alt="">
<!--              <img-->
<!--                      :class="((languages_menu) ? 'active' : '')"-->
<!--                      class="chevron"-->
<!--                      src="/dist/images/down-leng.svg"-->
<!--                      alt-->
<!--              />-->
            </v-list-item>
            <!--                    </v-btn>-->
          </v-list-item-action>
        </v-list>
      </template>
      <v-list class="block-all-lang ">
        <v-list-item
                class="text-right"
                v-for="(language, key) in languages"
                :key="key"
                v-on:click="selectLanguages(language.lang)"
                :class="(language.lang == active_language.lang) ? 'd-none' : ''"
        >
<!--          <img-->
<!--                  class=""-->
<!--                  :src="'/dist/images/flags/'+language.lang+'.svg'"-->
<!--                  :alt="`${language.lang}`"-->
<!--          />-->
          {{language.lang}}
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-else class="loading"></div>
  </div>
  <!-- <v-app  id="inspire">-->
  <!--      <v-overflow-btn-->
  <!--              class="dropdown"-->
  <!--              :items="languagesDropdown"-->
  <!--              target="#dropdown-example"-->
  <!--              v-model="active_languages"-->
  <!--              label="English"-->
  <!--              input-value="en"-->
  <!--      >-->
  <!--      </v-overflow-btn>-->
  <!--    </v-app> -->
</template>

<script>
    import store from "../../store";
    export default {
        name: "DropdownLanguages",
        data() {
            return {
                languages_menu: false,
                active_languages: null,
                loading: false,
                // dropdown_font: ["English", "Russian"],
            };
        },
        computed: {
            languages() {
                return this.$store.state.language.list;
            },
        },
        asyncComputed: {
            active_language() {
                let language = this.$store.state.language.lang,
                    languages = this.languages;

                if (language && languages) {
                    return this.getLanguage(language);
                } else {
                    return null;
                }
            },
        },
      components:{
      },
        methods: {
            selectLanguages: function (event) {
                let response= {
                    lang: event
                };

              this.$i18n.locale = event;

                if(event != this.lang) {
                    let params = this.$cleanObject(this.$route.params);
                    if(this.$route.name== 'profile' || this.$route.name== 'home') {
                        if(this.languages.map(function (e) { return String(e['lang']); }).indexOf(String(event)) < 0 && params.lang && !params.id) {
                            params['id'] = params.lang;
                        }
                    }
                    params['lang'] = (event == 'en') ? null :event;

                    store.commit("updateLanguage", event);
                    this.$router.push({ name: this.$route.name, params: params });
                    this.languages_menu = false;

                    this.$http
                        .post("ajax/set_locale", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            this.$checkAuth(response)
                            // if(this.$checkAuth(response)) {
                            // }
                        })
                        .catch((err) => {
                            this.httpHandler(err);
                        })
                }
            },
            getLanguage: function (event) {
                let languages = this.languages;

                let language = languages.filter((item) => {
                    return item.lang == event;
                });
                return language.length ? language[0] : languages[0];
            },
        },
    };
</script>