import Vue from 'vue';
import VueRouter from "vue-router";
import Main from "../components/Main";
import Office from "../components/Office";
import Authentication from "../components/Authentication";
import Home from "../components/pages/Home";
import AuthSignup from "../components/pages/AuthSignup";
import AuthLogin from "../components/pages/AuthLogin";
import AuthForgot from "../components/pages/AuthForgot";
import AuthResetPass from "../components/pages/AuthResetPass";
import AuthSecurity from "../components/pages/AuthSecurity";
import NotFound from '../components/pages/NotFound';
import ComingSoon from '../components/pages/ComingSoon';
import Catalog from '../components/pages/Catalog';
import ItemPage from '../components/pages/ItemPage';
// import Terms from "../components/pages/Terms";
// import Privacy from "../components/pages/Privacy";
// import Cookies from "../components/pages/Cookies";
import Blog from "../components/pages/Blog";
import BlogPage from "../components/pages/BlogPage";
import Pages from "../components/pages/Pages";
import Calc from "../components/pages/Calc";
import _ from "lodash";






import store from "../store/index";
import middlewarePipeline from "./middlewarePipeline";
import guest from "./middleware/guest";
import auth from "./middleware/auth";

Vue.use(VueRouter);
const routes= new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/:lang?/authentication',
            component: Authentication,
            children: [
                {
                    path: '/:lang?/registration',
                    name: 'register',
                    component: AuthSignup,
                    meta: {
                        title: 'Registration',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/:lang?/login',
                    name: 'login',
                    component: AuthLogin,
                    meta: {
                        title: 'Login',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/forgot',
                    name: 'forgot',
                    component: AuthForgot,
                    meta: {
                        title: 'Forgot',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/:lang?/security',
                    name: 'security',
                    component: AuthSecurity,
                    meta: {
                        title: 'Authentication security',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/:lang?/ajax/password/reset/:token',
                    name: 'password_reset',
                    component: AuthResetPass,
                    meta: {
                        title: 'Reset password',
                        middleware: [
                            guest
                        ]
                    },
                },
            ]
        },
        {
            path: '/:lang?/office',
            component: Office,
            children: [
                {
                    path: '/:lang?/office/staking',
                    name: 'staying',
                    component: ComingSoon,
                    meta: {
                        title: 'Coming Soon',
                        middleware: [
                            auth
                        ]
                    },
                },
                // {
                //     path: '/office/products',
                //     name: 'products',
                //     component: ComingSoon,
                //     meta: {
                //         title: 'Coming Soon',
                //         middleware: [
                //             auth
                //         ]
                //     },
                // },



            ]

        },
        {
            path: '/:lang?/logout',
            name: 'logout',
        },

        {
            path: '/:lang?/',
            component: Main,
            children: [
                {
                    path: '/:lang?/catalog',
                    name: 'catalog',
                    component: Catalog,
                    meta: {
                        title: 'Catalog',
                    },

                },
                {
                    path: '/:lang?/blog/:page_num(\\d+)?',
                    name: 'blog',
                    component: Blog,
                    meta: {
                        title: 'Blog',
                    },

                },
                {
                    path: '/:lang?/blog/:page',
                    name: 'BlogPage',
                    component: BlogPage,
                    meta: {
                        title: 'BlogPage',

                    },
                },

                // {
                //     path: '/:lang?/terms',
                //     name: 'terms',
                //     component: Terms,
                //     meta: {
                //         title: 'Terms',
                //     },
                //
                // },
                // {
                //     path: '/:lang?/privacy',
                //     name: 'privacy',
                //     component: Privacy,
                //     meta: {
                //         title: 'Privacy',
                //     },
                //
                // },
                // {
                //     path: '/:lang?/cookies',
                //     name: 'cookies',
                //     component: Cookies,
                //     meta: {
                //         title: 'Cookies',
                //     },
                //
                // },
                {
                    path: '/:lang?/pages/:page',
                    name: 'pages',
                    component: Pages,
                    meta: {
                        title: 'Pages',
                    },

                },
                {
                    path: '/:lang?/object/:url',
                    name: 'item_Page',
                    component: ItemPage,
                    meta: {
                        title: 'object',
                    },
                },
                {
                    path: '/:lang?/calc',
                    name: 'calc',
                    component: Calc,
                    meta: {
                        title: 'object',
                    },
                },


                {
                    path: (process.env.NODE_ENV === 'production') ? '/:lang?/' : '/:lang?/dist/',
                    name: 'home',
                    component: Home,
                    meta: {
                        title: '',
                    },

                },
            ]
        },
        {
            path: '/:lang?/*',
            name: 'not_found',
            component: NotFound,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return new Promise((resolve) => {
                // Проверяем, загружена ли страница полностью
                if (document.readyState === 'complete') {
                    // Если страница загружена, выполняем прокрутку сразу с задержкой
                    setTimeout(() => {
                        const element = document.querySelector(to.hash);
                        if (element) {
                            const offset = 100;
                            const top = element.getBoundingClientRect().top + window.pageYOffset - offset;
                            window.scrollTo({ top, behavior: 'smooth' });
                        }
                        resolve();
                    }, 300); // Добавляем задержку в 100 миллисекунд
                } else {
                    // Иначе добавляем обработчик на событие загрузки
                    window.addEventListener('load', () => {
                        setTimeout(() => {
                            const element = document.querySelector(to.hash);
                            if (element) {
                                const offset = 100;
                                const top = element.getBoundingClientRect().top + window.pageYOffset - offset;
                                window.scrollTo({ top, behavior: 'smooth' });
                            }
                            resolve();
                        }, 300); // Добавляем задержку в 100 миллисекунд
                    });
                }
            });
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});


routes.beforeEach((to, from, next) => {
    let path= to.path,
        reqex= new RegExp('^/'+store.getters.getLanguage);

    path= path.replace(reqex, '');

    if(!path) {
        path= '/';
    }
    // if(to.path.search(reqex) < 0) {
    // }

    if(to.params.lang== 'en' || to.params.lang && store.getters.getLanguage != to.params.lang && ((to.name != 'profile' && to.name != 'home') || to.params.id)) {
        let params = to.params;
        params['lang'] = null;

        return routes.push({ name: to.name, params: params });
    }

    if(to.name !='home' || to.name =='home' && !window.userAuth) {
        let seo_list= store.getters.getSeo[store.getters.getLanguage];
        let seo= _.find(seo_list, {'uri': path});

        let title = "Amphitrite";
        if(seo && seo.title) {
            document.title = seo.title + ' | ' + title;
        }
    }



    if (!to.meta.middleware) {
        return next();
    }
    const middleware = to.meta.middleware;

    const context = {
        to,
        from,
        next,
        store
    };


    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});


export default routes;
