<template>
  <div class="catalog-page" v-if="objects">
    <section class="catalog pc" id="catalog">
      <div class="bg">
        <div class="container content flex flex-col pc">
          <h2 class="big-h2" style="font-weight: 400">
            {{__('Real estate catalog')}}
          </h2>
          <div class="catalog-filters flex">
            <div class="catalog-filters flex">
              <div class="select-container flex flex-col ">
                <label class="p-font color-main" >{{__('Country')}}</label>
                <div class="select-wrapper  cursor-pointer  color-main" v-if="objects.countries">
                  <DropdownDefault
                          :items="objects.countries"
                          value_key="id"
                          v-model="form.country"
                          text="name"
                          placeholder="Select a country"
                  ></DropdownDefault>
                </div>
              </div>
              <div class="select-container flex flex-col ">
                <label class="p-font color-main" >{{__('City')}}</label>
                <div class="select-wrapper cursor-pointer color-main" v-if="objects.cities">
                  <DropdownDefault
                          v-if="form.country"
                          :items="countrieCity.cities"
                          value_key="id"
                          v-model="form.city"
                          text="name"
                          placeholder="Select a city"
                  ></DropdownDefault>
                  <DropdownDefault
                          v-else
                          disabled=""
                          placeholder="Select a city"
                  ></DropdownDefault>
                </div>
              </div>
              <div class="input-container flex flex-col ">
                <label class="p-font color-main" >{{__('Min price, from')}}</label>
                <div class="input-wrapper flex flex-row justify-between items-center">
                  <SearchDefault v-model="form.min_price" class_search="min_price" type="number" name="min" value="" inputmode="numeric"  v-on:keyup.enter="updateSearchObject('min_price')"  @searching="updateSearchObject()"/>
                  <div class="suffix input-font main-color">$</div>
                </div>
              </div>
              <div class="input-container flex flex-col ">
                <label class="p-font color-main" >{{__('Max price, before')}}</label>
                <div class="input-wrapper flex flex-row justify-between items-center">
                  <SearchDefault v-model="form.max_price"  class_search="max_price" type="number" name="max" value="" inputmode="numeric" v-on:keyup.enter="updateSearchObject('max_price')"  @searching="updateSearchObject()"/>
                  <div class="suffix input-font main-color">$</div>
                </div>
              </div>
              <div class="filters-row flex">
                <div class="flex buttons-container flex-col" v-if="objects.categories">

                  <div class="buttons-wrapper flex flex-row items-center flex-wrap" style="gap:10px" >
                    <div class="p-font color-main label-class">{{__('Plans:')}}</div>
                    <a
                            type="button"
                            class="property-icon p-font"
                            v-for="category in objects.categories"
                            :key="category.id"
                            :class="{ active: isCategoryActive(category.id, 'category') }"
                            @click="toggleCategory(category.id, 'category')"
                    >
                      {{ category[lang] }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="filters-row flex">
                <div class="flex buttons-container flex-col" v-if="objects.extras">

                  <div class="buttons-wrapper flex flex-row items-center flex-wrap" style="gap:10px" >
                    <div class="p-font color-main label-class">{{__('Extra:')}}</div>
                    <a
                            type="button"
                            class="property-icon p-font"
                            v-for="extra in objects.extras"
                            :key="extra.id"
                            :class="{ active: isCategoryActive(extra.id, 'extra') }"
                            @click="toggleCategory(extra.id, 'extra')"
                    >
                      {{ extra[lang] }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container content flex flex-col pc">
        <div class="catalog-objects" v-if="objects.objects.data.length">
          <PaginationDefault v-bind:data.sync="objects.objects"  @updateData="pageChange" v-bind:params="form">
            <template v-slot="page">
              <ul>
                <li v-for="object in page" :key="object.id" class="object-card">
                  <router-link :to="{ name: 'item_Page', params: { url: object.url, lang: $store.getters.getLang } }">
                    <div class="object-image" :style="{ backgroundImage: 'url(/' + object.images[0].path + ')' }" v-if="object.images.length"></div>
                    <div class="object-image" :style="{ backgroundImage: 'url(/dist/images/default-img.svg)' }" v-else></div>
                    <div class="object-info">
                      <div class="name-price">
                        <p class="name">{{ object.name }}</p>
                        <p class="price" v-if="object.sold == 0">{{  object.currency ?  object.currency.symbol : '$' }}{{ object.min_price.toLocaleString() }} <span class="p" v-if="object.max_price && object.max_price > 0">-{{  object.currency ?  object.currency.symbol : '$' }}{{ object.max_price.toLocaleString() }}</span> <span>{{__('Price')}}</span></p>
                      </div>
                      <div class="block">
                        <p><img src="/dist/images/icon-home.svg" alt=""> Q{{ object.quarter }} {{ object.years }}</p>
                        <p><img src="/dist/images/icon-location.svg" alt="">{{ object.address }}</p>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </template>
          </PaginationDefault>
        </div>
        <div v-else class="no-result">
          <img src="/dist/images/find-on.svg" alt="">
          <p>{{__('The search has not given any results')}}</p>
        </div>
      </div>

    </section>



  </div>
</template>

<script>
  import _ from 'lodash';
  import DropdownDefault from "../modules/DropdownDefault";
  import SearchDefault from "../modules/SearchDefault";
  import PaginationDefault from "../modules/PaginationDefault";
  export default {
    name: 'catalog',
    data() {
      let query= this.$route.query;

      return {
        form: {
          country: (query.country) ? Number(query.country) : null,
          city:  (query.city) ? Number(query.city) :  null,
          min_price:  (query.min_price) ? query.min_price :  null,
          max_price:  (query.max_price) ? query.max_price :  null,
          category:  (query.category) ? query.category :  '[]',
          extra:  (query.extra) ? query.extra :  '[]',
        },
        objects: null,
        activeExtras: [],
        activeCategories: [],
      };
    },
    watch: {
      lang(newLang, oldLang) {
        if(newLang !== oldLang) {
          this.updateObject(true);
        }
      },
      'form.country'(val, prev) {
        this.form.city = null;
        if(val != prev && val !== null) {
          this.updateObject();
        }
      },
      'form.city'(val, prev) {
        if(val != prev && val !== null) {
          this.updateObject();
        }
      },
      'form.category'(val, prev) {
        if(val != prev && val !== null && val != '[]') {
          this.updateObject();
        }
      },
      'form.extra'(val, prev) {
        if(val != prev && val !== null && val != '[]') {
          this.updateObject();
        }
      }
    },
    methods: {
      pageChange(response) {
        this.objects = response;
      },
      updateSearchObject(value) {
        if(this.form[value] !== null) {
          this.updateObject();
        }
      },
      isEmptyValue(value) {
        // Проверка на null или undefined
        if (_.isNil(value)) {
          return true;
        }

        // Проверка на пустую строку
        if (_.isString(value) && value.trim() === '') {
          return true;
        }

        // Проверка на пустой массив
        if (_.isArray(value) && _.isEmpty(value)) {
          return true;
        }

        // Проверка на пустой объект
        if (_.isObject(value) && _.isEmpty(value) && !_.isFunction(value)) {
          return true;
        }

        // Если ни одно из условий не выполнено, значение не пустое
        return false;
      },
      updateObject(update = false) {
        let form = this.$cleanObject(this.form);

        if(!update) {
          const cleanedObj = _.omitBy(form, this.isEmptyValue);

          let routeParams = this.$cleanObject(this.$route.params);
          routeParams['lang'] = this.$store.getters.getLang;

          this.$router.push({name: this.$route.name, params: routeParams, query: cleanedObj});
        }

        let params = {
          country: form.country,
          city: form.city,
          min_price: form.min_price,
          max_price: form.max_price,
          category: JSON.parse(form.category),
          extra: JSON.parse(form.extra),
          lang: this.lang,
        };

        console.log('Current language:', this.lang); // Correctly logs the updated language
        console.log('Request params:', params);

        this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/objects', params)
                .then(response => response.json())
                .then(response => {
                  console.log('Data received:', response);
                  this.objects = response.data;
                })
                .catch(err => {
                  console.error('Error fetching data:', err);
                });
      },
      toggleCategory(id, type = 'category') {
        let category = JSON.parse(this.form[type]);
        let find = _.indexOf(category, id);

        if(find >= 0) {
          category.splice(find, 1);
        } else {
          category.push(id);
        }

        this.form[type] = JSON.stringify(category);
      },
      isCategoryActive(id, type = 'category') {
        let category = JSON.parse(this.form[type]);
        return (_.indexOf(category, id) >= 0);
      }
    },
    created() {
      this.updateObject(true);
    },
    computed: {
      countrieCity() {
        return (this.objects) ? _.find(this.objects.countries, {id: this.form.country}) : null;
      },
      languages() {
        return this.$store.state.language.list;
      },
      lang() {
        return this.$store.state.language.lang;
      },
    },
    beforeRouteUpdate (to, from, next) {
      if(to.fullPath == to.path) {
        this.form = {
          country: null,
          city: null,
          min_price: null,
          max_price: null,
          category: '[]',
          extra: '[]',
        };

        this.updateObject(true);
      }

      next();
    },
    components: {
      PaginationDefault,
      SearchDefault,
      DropdownDefault
    }
  }
</script>